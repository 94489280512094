import React from "react";
import Checkin from "../components/checks/Checkin";
import { useParams } from "react-router-dom";
import { DatabaseService } from "../context/DatabaseContext";
import { useQuery } from "@tanstack/react-query";

const CheckinPage = () => {

  const { getEmployees } = DatabaseService();
  const { id } = useParams();

  // GET EMPLOYEES 
  const { data: employees } = useQuery({
    queryFn: () => getEmployees(id),
    queryKey: ["employees"],
  });

  return (
    <div className="">
      <Checkin employees={employees}/>
    </div>
  );
};

export default CheckinPage;
