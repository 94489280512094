import React, { createContext, useContext } from "react";
import { db } from "../config/firebase";

import {
    collection,
    // getDocs,
    query,
    // orderBy,
    // onSnapshot,
    doc,
    where,
    addDoc,
    // updateDoc,
    getDocs,
    // setDoc,
    // documentId,
    getDoc,
} from "firebase/firestore";

// COLLECTION REFERENCES 

const employeesCollection = collection(db, 'employees');
const qrCollection = collection(db, 'qr_codes');
const branchCollection = collection(db, "branches");
const checkinsCollection = collection(db, "checkins");

export const FirestoreContext = createContext();

export const FirestoreContextProvider = ({ children }) => {

    // GET QR CODE DOCUMENT FROM PAGE ID 
    const getQrCode = async (id) => {
        const data = await getDoc(doc(qrCollection, id));
        return data.data();
    }

    // GET BRANCH DOCUMENT USING ID 
    const getBranch = async (id) => {
        const data = await getDoc(doc(branchCollection, id));
        return data.data();
    }

    // GET ALL EMPLOYEES FROM THE BRANCH
    const getEmployees = async (id) => {
        const q = query(employeesCollection, where("branchId", "==", id));
        const querySnapshot = await getDocs(q);
        const employees = [];
        querySnapshot.forEach((doc) => {
            employees.push(doc.data());
        });
        return employees;
    }

    // GET CHECKINS FROM EMPLOYEE ID THAT ARE PENDING AND LIMIT TO 2
    const getPendingCheckins = async (id) => {
        const q = query(checkinsCollection, where("employeeId", "==", id), where("status", "==", "pending"));
        const querySnapshot = await getDocs(q);
        const checkins = [];
        querySnapshot.forEach((doc) => {
            checkins.push(doc.data());
        });
        return checkins;
    }



    // GET ALL EMPLOYEES FROM THE BRANCH 
    // const getEmployees = async (id) => {
    //     const q = query(employeesCollection, where("branchId", "==", id));
    //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //         const employees = [];
    //         querySnapshot.forEach((doc) => {
    //             employees.push(doc.data());
    //         });
    //         return employees;
    //     });
    //     return unsubscribe;
    // }



    // CREATE FUNCTIONS 
    const checkin = async (data) => {
        const docRef = await addDoc(checkinsCollection, data);
        return docRef;
    }





    return (
        <FirestoreContext.Provider
            value={{
                getQrCode,
                getBranch,
                getEmployees,
                getPendingCheckins,

                // CREATE FUNCTIONS 
                checkin,
            }}
        >
            {children}
        </FirestoreContext.Provider>
    );
}

export const DatabaseService = () => {
    return useContext(FirestoreContext)
}