import React, { useEffect, useState } from "react";
import SplashScreen from "../components/messages/SplashScreen";
import CheckType from "../components/checks/CheckType";
import { useParams } from "react-router-dom";
import { DatabaseService } from "../context/DatabaseContext";
import { useQuery } from "@tanstack/react-query";

const LandingPage = () => {
  // GET QR CODE FROM PAGE ID
  const { id } = useParams();

  // CREATE STATE
  const [splashScreen, setSplashScreen] = useState(true);

  // CREATE TIMER FOR SPLASH SCREEN
  useEffect(() => {
    setTimeout(() => {
      setSplashScreen(false);
    }, 2000);
  });

  // GET DATA FROM FIRESTORE
  const { getQrCode, getBranch } = DatabaseService();



  // USEQUERY TO GET DATA

  // GET THE QR CODE FROM THE PAGE ID 
  const { data: branchQr } = useQuery({
    queryFn: () => getQrCode(id),
    queryKey: ["qrcode"],
  });

  // GET THE BRANCH ID FROM THE QR CODE
  const branchId = branchQr?.branchId;

  // GET THE BRANCH DATA FROM THE BRANCH ID
  const {  data: branchData } = useQuery({
    queryKey: ["branch", branchId],
    queryFn: () => getBranch(branchId),
    enabled: !!branchId,
  });






  return (
    <div className="min-h-screen">
      {splashScreen ? <SplashScreen /> : <CheckType id={id} branch={branchData}   />}
    </div>
  );
};

export default LandingPage;
