import React from 'react'
import { Link } from 'react-router-dom'

const CheckType = ({ id, branch, employees }) => {
    return (
        <div className='min-h-screen flex flex-col bg-green-50'>
            <img src="/images/logos/black.png" alt="" className='w-[150px] px-1 py-2' />
            <h3 className='font-bold text-3xl text-center py-10'>Are you checking in or checking out? </h3>
            <div className="w-full px-2 flex flex-row gap-3 md:w-2/3 md:justify-center ml-auto mr-auto">
                <div className=" w-1/2 rounded-lg shadow-lg bg-white">
                    <Link to={`/checkin/${branch?.branchId}`}>
                        <div className=" h-[150px] md:h-[350px] rounded-lg overflow-hidden">
                            <img src="/images/backgrounds/womansy.png" alt="" />
                        </div>
                        <h2 className='p-2 font-bold text-xl'>Check in</h2>
                    </Link>
                </div>

                <div className="w-1/2 rounded-lg bg-white shadow-lg">
                    <Link to='/checkout'>
                        <div className="h-[150px] md:h-[350px] rounded-lg overflow-hidden">
                            <img src="/images/backgrounds/question.png" alt="" />
                        </div>
                        <h2 className='p-2 font-bold text-xl'>Check out</h2>
                    </Link>
                </div>
            </div>

            <h3 className='font-regular text-xl text-center py-10 px-2 md:w-2/3 mr-auto ml-auto'>You have now reached the <span className='font-bold'>{branch?.branch}</span> portal.
                Select an option above whether you are checking in or checking out. </h3>


        </div>
    )
}

export default CheckType