
const SplashScreen = () => {

    return (
        <div className='h-screen w-full bg-red-400'>
            <div className="bg-white h-screen w-full grid place-items-center justify-center">
                <img src="/images/logos/black.png" alt="" className='w-2/3' />
            </div>
        </div>
    )
}

export default SplashScreen