import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import CheckinPage from "./pages/CheckinPage";
import CheckoutPage from "./pages/CheckoutPage";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

function App() {

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="">
        <Routes>
          <Route path="/:id" element={<LandingPage />} />
          <Route path="/checkin/:id" element={<CheckinPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
        </Routes>
      </div>
    </QueryClientProvider>
  );
}

export default App;
