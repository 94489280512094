import React from 'react'
import Checkout from '../components/checks/Checkout'

const CheckoutPage = () => {
  return (
    <div>
        <Checkout />
    </div>
  )
}

export default CheckoutPage