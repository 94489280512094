import React from 'react'
import moment from 'moment/moment'

const PendingCheckins = ({ checkin }) => {
  return (
    <div className='w-full bg-red-200 mt-1 py-2 px-2 rounded-md'>
        {/* moment */}
        <p className='text-sm font-semibold'>
            {moment(checkin.timeIn.toDate()).format('MMMM Do YYYY, h:mm:ss a')}
        </p>
        <p>{checkin.status}</p>
        
    </div>
  )
}

export default PendingCheckins