import React from 'react'

const Checkout = () => {
    return (
        <div className="min-h-screen w-full flex flex-col justify-center items-center">
            <div className="w-full grid place-items-center  ">
                <img
                    src="/images/backgrounds/scant.png"
                    alt=""
                    className="w-full md:w-1/2"
                />
            </div>
            <h3 className="py-5 px-2 font-bold text-2xl md:w-2/6">
                Enter your phone number to check out
            </h3>
            <form action="" className="w-full px-2 md:p-0   md:w-2/6">
                <input
                    className=" rounded-full py-3 px-4 text-gray-700 leading-tight border-none focus:border-none w-full bg-gray-200"
                    id="username"
                    type="tel"
                    placeholder="Phone Number"
                />

                <button type="submit" className="bg-black w-full my-4 py-3 rounded-full font-bold text-white">
                    CHECKOUT
                </button>
            </form>
        </div>
    )
}

export default Checkout