import React, { useState } from 'react'
import { DatabaseService } from '../../context/DatabaseContext';
import { useQuery } from '@tanstack/react-query';
import PendingCheckins from '../tiles/PendingCheckins';

const Checkin = ({ employees }) => {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [employee, setEmployee] = useState(null);

    const { checkin, getPendingCheckins } = DatabaseService();

    // GET PENDING CHECKINS  
    const employeeId = employee?.employeeId;
    
    const {data: pendingCheckins} = useQuery({
        queryFn: () => getPendingCheckins(employeeId),
        queryKey: ['pendingCheckins', employeeId],
        enabled: !!employeeId
    });

    console.log(pendingCheckins);

    console.log(employeeId);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(phoneNumber);

        var employee = employees.find(employee => employee.phone === phoneNumber);
        setEmployee(employee);
    }

    const handleBack = () => {
        setEmployee(null);
    }

    const handleCheckin = () => {
        console.log('checkin');

        const checkinData = {
            employeeId: employee.employeeId,
            branchId: employee.branchId,
            timeIn: new Date(),
            status: 'pending'
        }
        try {
            checkin(checkinData);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="min-h-screen w-full flex flex-col justify-center items-center">
            <div className="w-full grid place-items-center  ">
                <img
                    src="/images/backgrounds/scant.png"
                    alt=""
                    className="w-full md:w-1/2"
                />
            </div>
            <h3 className="py-5 px-2 font-bold text-2xl md:w-2/6">
                Enter your phone number to check in
            </h3>
            <form onSubmit={handleSubmit} className="w-full px-2 md:p-0   md:w-2/6">
                <input
                    className=" rounded-full py-3 px-4 text-gray-700 leading-tight border-none focus:border-none w-full bg-gray-200"
                    id="username"
                    type="tel"
                    placeholder="Phone Number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />

                {
                    pendingCheckins && pendingCheckins.map((checkin, index) => (
                        <PendingCheckins key={index} checkin={checkin} />
                    ))
                }

                {
                    employee && <h3 className='px-4 font-semibold text-center mt-2'>
                        <span>
                            {employee.firstName} {employee.lastName}
                        </span>
                    </h3>
                }

                {
                    employee ?

                        <>
                            <button type="button" onClick={() => handleCheckin()} className="bg-black w-full my-2 py-3 rounded-full font-bold text-white">
                                CHECKIN
                            </button>
                            <button type="button" onClick={() => handleBack()} className="text-red-600 w-full my-2 py-2 rounded-full font-bold   border-red-600 border-solid border-2">
                                BACK
                            </button>
                        </>
                        :
                        <button type="submit" className="bg-black w-full my-4 py-3 rounded-full font-bold text-white">
                            CONFIRM DETAILS
                        </button>
                }


            </form>
        </div>
    )
}

export default Checkin